import React from "react"
import { Layout } from "../layouts/Layout"
import { SiteMetadata, SVG, GalleryCarousel, GalleryCert, MapYaObjects} from "../components"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

import { Container, Row, Col} from "react-bootstrap"

export default ({ data }) => {  

  const imagesHeader = [
    data.headerMobile.childImageSharp.fluid,
    {
      ...data.header.childImageSharp.fluid,
      media: `(min-width: 576px)`,
    }
  ]  

  return (
    <Layout>
      <SiteMetadata
        title='ООО "Металл" - строительная компания'
        description="Компания с 30-летним опытом работы в строительстве. Мы выполняем функции генерального подрядчика и имеем опыт в выполнении широкого спектра строительных работ."
        canonical="/"
        image="/og.jpg"
      />
      <BackgroundImage
          Tag="div"
          className={"container-fluid"}
          fluid={imagesHeader}
          style={{backgroundColor: `#00435e`}}
        >
          <Container fluid className="dots-before py-3 py-sm-5">
            <Container className="py-5">
            <Row>
              <Col lg={{span: 8, offset: 2}} className="text-center text-white">
                <Img fluid={data.logo.childImageSharp.fluid} className="mx-auto mb-2" style={{maxWidth : '80px'}} alt="ООО Металл - строительная компания"/>
                <h1 className="d-inline-block px-5 mb-3 mb-md-5">Металл</h1>
                <p>
                  ООО “Металл” - стабильно развивающаяся компания с 30-летним опытом работы в строительстве. 
                  Мы выполняем функции генерального подрядчика и имеем опыт в выполнении широкого спектра строительно-монтажных и санитарно-технических работ.
                </p>
                <a className="btn btn-outline-primary mt-3 mt-sm-5" href="#objects">Наши объекты</a>
              </Col>
            </Row>
          </Container>
          </Container>
      </BackgroundImage>
      <Container id="about">
        <Row className="my-5">
          <Services svg="attestate" text="Аттестат соответсвия Министерства архитектуры и строительства РБ (III категории)"/>
          <Services svg="certificate" text="Сертификат качества строительно-монтажных работ СТБ ISO 9001-2009"/>
          <Services svg="crane" text="Наличие собственной производственной базы и парка техники"/>
          <Services svg="expiriance" text="Многолетний опыт работы, позволяющий выполнять проекты в сжатые сроки"/>
        </Row>
        <Row className="mb-5">          
          <Col sm={{span: 6, offset: 3}} lg={{span: 4, offset: 0, order: 2}}>
            <GalleryCert images={data.cert.edges} alt="Аттестаты ООО Металл"/>
          </Col>
          <Col lg={8} className="mb-3 d-flex flex-wrap align-content-center mt-5 mt-lg-0">
            <h2 className="mb-4 text-center text-md-left">Государственная аттестация</h2>
            <p>
              ООО “Металл” имеет аттестацию Министерства архитектуры и строительства РБ по следующим видам работ:
            </p>
            <ul>
              <li>Строительство зданий и сооружений</li>
              <li>Монтаж технологических трубопроводов</li>
              <li>Устройство оснований, фундамента и кровли</li>
              <li>Монтаж сборных, несущих элементов и конструкций</li>
              <li>Устройство фасадных систем теплоизоляции и облицовки зданий</li>
              <li>Устройство внутренних и наружных систем водоснабжения, теплоснабжения</li>
              <li>Монтаж систем вентиляции и кондиционирования воздуха</li>              
            </ul>         
          </Col>
        </Row>
      </Container>      
      <Container fluid>
        <Row>
          <Col xs={12} md={6} className="px-0 dots">
            <Img fluid={data.peoples.childImageSharp.fluid} className="h-100 img-top-right" alt="Сотрудники ООО Металл"/>          
          </Col>
          <Col md={6} className="d-flex flex-wrap align-content-center text-center text-md-right" style={{backgroundColor: `#e9eaec`}}>
            <div className="pl-lg-5 container">
              <h2 className="my-5">Компетентные сотрудники</h2>
              <p>
                На предприятии ООО “Металл” трудится более 250 человек. 
                Руководящие работники имеют квалификационные аттестаты ИРУП “Белстройцентр”. 
                Так же все сотрудники регулярно проходят профессиональное обучение.
              </p>
              <Row className="my-5">
                <CountUp number={250} text={"Сотрудников работает на предприятии"} />
                <CountUp number={27} text={"Инженерно-технических специалистов"} />
                <CountUp number={20} text={"Аттестованных руководящих работников"} />
              </Row>
            </div>            
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>   
          <Col xs={12} md={{span: 6, order: 2}} className="px-0 dots">
            <Img fluid={data.tech.childImageSharp.fluid} className="h-100 img-top-left" alt="Сотрудники ООО Металл"/>          
          </Col>       
          <Col md={6} className="d-flex flex-wrap align-content-center text-center text-md-left" style={{backgroundColor: `#e9eaec`}}>
            <div className="container">
              <h2 className="my-5">Собственный парк техники</h2>
              <p>
              Наша компания обладает собственным парком техники, что позволяет самостоятельно выполнять любые виды работ. 
              В целом включает в себя 37 единиц.
              </p>
              <Row className="mt-5">
                <Col  xs={6} sm={12}>
                  <Row>
                    <Transport svg={"autokrane"} text={"Автокраны"} />
                    <Transport svg={"exkavator"} text={"Экскаваторы"} />                    
                    <Transport svg={"bolshegruz"} text={"Большегрузная техника"} />
                  </Row>
                </Col>                
                <Col xs={6} sm={12}>
                  <Row>
                    <Transport svg={"smesitel"} text={"Бетоносмеситель"} />                    
                    <Transport svg={"microautobus"} text={"Микроавтобусы"} />
                    <Transport svg={"car"} text={"Легковые автомобили"} />
                  </Row>
                </Col>
              </Row>
            </div>            
          </Col>          
        </Row>
      </Container>
      <Container className="my-5" id="objects">
        <Row className="text-center mb-5">
          <Col lg={{span: 8, offset: 2}}>
          <h2 className="mb-4">Выполненные объекты</h2>
          <p>ООО “Металл” производит строительство и обслуживание объектов торговли и магазинов, торговых центров, производственных предприятий.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <GalleryCarousel images={data.gallery.edges} alt="Объекты ООО Металл"/> 
          </Col> 
        </Row>
      </Container>
      <Container className="mt-5 py-5">
        <Row>
          <Col md={{span: 5, order: 2}} lg={{span: 4, offset: 1}} className="text-center text-md-right mb-3">
            <h2 className="mt-3 mb-5">Карта объектов</h2>
            <div className="mb-5">
              На карте отображено расположение проектов выполненных за период 2018 - 2019 годах, а так же находящихся в процессе строительства
            </div>
            <div className="d-flex align-items-center justify-content-center justify-content-md-end text-small mb-1">
              Готовые объекты
              <Img fixed={data.geoBlue.childImageSharp.fixed} className="ml-2" alt="готовые объекты ООО Металл"/>
            </div>
            <div className="d-flex align-items-center justify-content-center justify-content-md-end text-small">
              В процессе строительства
              <Img fixed={data.geoYellow.childImageSharp.fixed} className="ml-2" alt="объекты в процессе строительства ООО Металл"/>
            </div>
          </Col>
          <Col style={{minHeight: "350px"}}>
            <MapYaObjects />
          </Col>          
        </Row>
      </Container>
      <Container className="text-center">
        <Row>
          <Col><h2 className="my-5">С нами работают</h2></Col>
        </Row>
        <Partners images={data.partners.edges}/>
      </Container>
    </Layout>    
  )
}

const Services = ({svg, text}) => {
  return (
    <Col className="mb-3 text-center">
      <div className="mb-3"><SVG style={{maxWidth: '65px'}} type={svg}/></div>
      <div className="text-small">{text}</div>
    </Col>
  )      
}

const CountUp = ({number, text}) => {
  return (
  <Col className="text-center">
    <div className="display-5">{number}</div>
    <div className="text-small">{text}</div>
  </Col>
  )
}

const Transport = ({svg, text}) => {
  return (
  <Col sm={4} lg={3} className="mb-4 text-center">
      <div className="mb-2"><SVG style={{maxWidth: '56px'}} type={svg}/></div>
      <div className="text-small">{text}</div>
    </Col>
  )
}

const Partners = ({images}) => {
  return(
    <Row>
      {images.map((item, i) => (
        <Col xs={4} md className="mb-5" key={`partner_${i}`}><Img fluid={item.node.childImageSharp.fluid} imgStyle={{objectFit: "contain"}} alt="партнеры ООО Металл"/></Col>
      ))}
    </Row>
  )
}

export const query = graphql`
  query IndexQuery {
    logo: file(relativePath: { eq: "images/logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }  
    header: file(relativePath: { eq: "images/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }  
    headerMobile: file(relativePath: { eq: "images/header-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 576) {
          ...GatsbyImageSharpFluid
        }
      }
    }       
    tech: file(relativePath: { eq: "images/tech.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    peoples: file(relativePath: { eq: "images/peoples.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    } 
    geoBlue: file(relativePath: { eq: "images/placemark-blue.png" }) {
      childImageSharp {
        fixed(width: 24, height: 31) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    } 
    geoYellow: file(relativePath: { eq: "images/placemark-yellow.png" }) {
      childImageSharp {
        fixed(width: 24, height: 31) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    } 
    gallery: allFile(filter: {dir: {regex: "/data/images/gallery/"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    cert: allFile(filter: {dir: {regex: "/data/images/cert/"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 355, maxHeight: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    partners: allFile(filter: {dir: {regex: "/data/images/partners/"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 155, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`